import React, { useState } from "react";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import ModalVideo from "react-modal-video";
import ChatBanner from "components/Software/ChatBanner";
import Footer from "components/Saas/Footer";
import { Link } from "gatsby";
import Blog10 from "components/Software/Blog10";


const AutoFinance = () => {

  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  }
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#dc3545'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Drive Your Dreams with   <span> Flexible Auto Finance </span></h1>
                <p className="p">Own your ideal vehicle with our affordable financing options
Button: Apply for Auto Finance
 </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">       
        <img src="/assets/img/about/style_3_1.png" alt=""  style={{ maxWidth: '30%'}} />   
         
        <img
                    src="/assets/img/about/AUTO LOAN.png"
                    alt=""
                    className="img-body "
                  />
      </div>
    </header>
      <main>
        <section
          className="about section-padding style-3"
          data-scroll-index="3"
        >
          <div className="container">
            <div className="row gx-0 justify-content-end">
              <div className="col-lg-6">
                <div className="img  ">
                  <img
                    src="/assets/img/about/AUTO LOAN.png"
                    alt=""
                    className="img-body "
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="section-head long-shape mb-40 style-3">
                    <h3>Auto Finance  </h3>
                  </div>
                  <p>
                  Realize your dream of vehicle ownership with our tailored Auto Finance solutions, designed to make purchasing a car simple and affordable. With competitive rates, flexible repayment terms, and a fast approval process, our Auto Finance is ideal for individuals and businesses looking to invest in reliable transportation. Whether it's a personal vehicle or a fleet for business, we make vehicle financing easy and stress-free.
                  </p>
                  <div className="text mb-20 mt-20">
                    <h5>Requirements: </h5>
                    <ul>
                      <li>Valid Identification (National ID, Driver’s License, or Passport)</li>
                      <li>Bank Verification Number (BVN)</li>
                      <li>Completed KYC verification</li>
                      <li>Proof of income</li>
                      <li>Quotation for the desired vehicle</li>
                    </ul>
                  </div>
                  <Link
                    to="/contact"
                    className="btn rounded-pill bg-blue2 sm-butn text-white mt-10"
                  >
                    <span>Apply for Auto Finance</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Blog10 />
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
     <title> Auto Asset Finance – Purchase your new car with no hassles</title>
<meta name="description" content=" Whether you're buying a new or used car, we've got you covered with our flexible Auto Asset Finance option. Access our Auto financing options with no hassles 
" />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default AutoFinance;
